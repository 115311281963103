import CaseTypeEnum from 'shared/enum/CaseTypeEnum';
import React, { Component } from 'react';
import Request from 'shared/interceptors/Request';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  Button,
  ContentHeader,
  Drawer,
  Level,
  Modal,
  Section,
  TextField,
  withSnackbar,
} from '@rlx-pros/react-foundation';
import { CONFLICT, OK } from 'http-status-codes';
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';
import CreateCaseInstructions from './createCaseInstructions';

class CreateCaseDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amazonCaseId: '',
      isOpen: props.open,
      modalOpen: false,
      loading: false,
      caseDetails: {},
      submitting: false,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      isOpen: nextProps.open,
    };
  }

  componentDidUpdate(prevProps) {
    const { open } = this.props;

    if (open && !prevProps.open) {
      this.getCaseDetails();
    }
  }

  getCaseDetails = () => {
    this.setState({ loading: true });

    const { caseInfo, enqueueSnackbar } = this.props;
    const { id, typeEnum } = caseInfo;
    const url = `${process.env.REACT_APP_ORCHESTRATOR}/case-details`;

    Request.get(url, {
      params: {
        caseId: id,
        caseType: typeEnum,
      },
    }).then((response) => {
      const { data, statusCode } = response.data;
      const { open } = this.props;

      // TODO: Handle closing drawer to create different case
      if (statusCode === OK && open) {
        const { caseDetails } = data;
        this.setState({ caseDetails: caseDetails[0] });
      } else if (statusCode !== OK) {
        console.error(data);
        enqueueSnackbar(data, { variant: 'error' });
      }
    }).catch((error) => {
      console.error(error);
    }).finally(() => {
      this.setState({ loading: false });
    });
  };

  validateAmazonId = (event) => {
    event.preventDefault();
    const { amazonCaseId } = this.state;
    const { enqueueSnackbar } = this.props;

    if (!amazonCaseId.match(/^\d{10,11}$/)) {
      let message = '';
      if (amazonCaseId.length < 10) {
        message = 'Amazon Case ID is too short. Please make sure it is exactly 10 or 11 digits';
      } else if (amazonCaseId.length > 11) {
        message = 'Amazon Case ID is too long. Please make sure it is exactly 10 or 11 digits';
      } else {
        message = 'Amazon Case ID is invalid. The ID should consist of only numbers';
      }
      enqueueSnackbar(message, { variant: 'error' });
    } else {
      this.setState({ submitting: true });

      const url = `${process.env.REACT_APP_ORCHESTRATOR}/create-case`;
      // TODO: Get marketplace
      const params = {
        amazonCaseId,
      };

      Request.get(url, { params })
        .then((result) => {
          const { statusCode, message } = result.data;
          let submitting = false;

          if (statusCode === OK) {
            this.createCase();
            submitting = true;
          } else if (statusCode === CONFLICT) {
            // Confirmation Modal
            this.setState({ modalOpen: true });
          } else {
            enqueueSnackbar(message, {
              variant: 'error',
              persist: true,
            });
          }

          if (!submitting) {
            this.setState({ submitting });
          }
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
          });

          this.setState({ submitting: false });
        });
    }
  }

  createCase = () => {
    this.setState({
      submitting: true,
      modalOpen: false,
    });

    const { amazonCaseId } = this.state;
    const { caseInfo, enqueueSnackbar, refresh } = this.props;
    const { id } = caseInfo;
    const url = `${process.env.REACT_APP_ORCHESTRATOR}/create-case`;

    const params = {
      amazonCaseId,
      caseId: id,
    };

    Request.put(url, {}, { params })
      .then((result) => {
        const { statusCode, message } = result.data;

        if (statusCode !== OK) {
          enqueueSnackbar(message, { variant: 'error', persist: true });
        } else {
          enqueueSnackbar('Save Successful', { variant: 'success' });
          refresh();
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.message, {
          variant: 'error',
          persist: true,
        });
      })
      .finally(() => {
        this.setState({
          submitting: false,
        });

        this.close();
      });
  }

  close = () => {
    const { close } = this.props;
    this.setState({ caseDetails: {} });
    close();
  };

  closeModal = () => this.setState({ modalOpen: false });

  duplicateModal = () => {
    const { modalOpen, amazonCaseId } = this.state;

    return (
      <Modal
        open={modalOpen}
        onClose={this.closeModal}
      >
        <div>{`Amazon Case ID ${amazonCaseId} already exists.`}</div>
        <div>You should only save multiple cases with a single Amazon Case ID if you have grouped cases together.</div>
        <br />
        <div>Click OK to continue saving.</div>
        <Level>
          <Level.Side align="left" />
          <Level.Side align="right">
            <Level.Item>
              <Button
                className="is-pulled-right"
                color="info"
                onClick={this.createCase}
              >
                OK
              </Button>
            </Level.Item>
            <Level.Item>
              <Button
                className="is-pulled-right"
                color="danger"
                onClick={this.closeModal}
              >
                Cancel
              </Button>
            </Level.Item>
          </Level.Side>
        </Level>
      </Modal>
    );
  };

  render() {
    const { anchor, caseInfo } = this.props;
    const {
      isOpen,
      loading,
      submitting,
      caseDetails,
      amazonCaseId,
    } = this.state;

    const instructions = CreateCaseInstructions.getInstructions(caseInfo.typeEnum);
    const isInboundDiscrepancy = CaseTypeEnum[caseInfo.typeEnum] === CaseTypeEnum.INBOUND_DISCREPANCY;

    let url = '';
    let contactReason = '';
    let description = '';

    if (!loading && isOpen) {
      const createCaseInstructions = new CreateCaseInstructions(caseInfo, caseDetails);
      url = createCaseInstructions.getUrl();
      const caseDescription = createCaseInstructions.getDescription();

      contactReason = caseDescription.contactReason;
      description = caseDescription.description;
    }

    const skeleton = <Skeleton variant="text" height={50} />;

    return (
      <Drawer open={isOpen} anchor={anchor || 'right'} onClose={this.close}>
        <Drawer.Bar close={this.close} />
        <ContentHeader title="Create Case" size="medium" color="white" />
        <Section>
          <strong>Instructions:</strong>
          <br />
          {instructions}
          <br />
          {loading ? skeleton : <a target="_blank" rel="noopener noreferrer" href={url}>{url}</a>}
        </Section>
        {!isInboundDiscrepancy && (
          <Section>
            <div>
              <strong>Contact Reason</strong>
              <CopyToClipboard text={contactReason}>
                <Button size="small" color="info" className="is-pulled-right" loading={loading}>Copy</Button>
              </CopyToClipboard>
            </div>
            <br />
            {loading ? skeleton : (
              <TextField
                id="contactReason"
                value={contactReason || ''}
                variant="outlined"
                size="small"
                disabled
                fullWidth
              />
            )}
          </Section>
        )}
        {!isInboundDiscrepancy && (
          <Section>
            <div>
              <strong>Please describe your issue</strong>
              <CopyToClipboard text={description}>
                <Button size="small" color="info" className="is-pulled-right" loading={loading}>Copy</Button>
              </CopyToClipboard>
            </div>
            <br />
            {loading ? skeleton : (
              <TextField
                id="description"
                value={description || ''}
                variant="outlined"
                size="small"
                disabled
                fullWidth
                multiline
                rowsMax={12}
              />
            )}
          </Section>
        )}
        <Section>
          <div>
            <strong>IMPORTANT </strong>
            After creating a case with Amazon, input your Amazon Case ID here to ensure all reports reflect the most recent data.
          </div>
        </Section>
        <Section>
          <strong>Amazon Case ID</strong>
          <TextField
            id="amazonCaseId"
            value={amazonCaseId}
            onChange={(event) => this.setState({ amazonCaseId: event.target.value })}
            variant="outlined"
            size="small"
            fullWidth
          />
          <br />
        </Section>
        <Level>
          <Level.Side align="left" />
          <Level.Side align="right">
            <Level.Item>
              <Button
                className="is-pulled-right"
                color="info"
                onClick={this.validateAmazonId}
                loading={loading || submitting}
              >
                Create Case
              </Button>
            </Level.Item>
          </Level.Side>
        </Level>
        {this.duplicateModal()}
      </Drawer>
    );
  }
}

export default withSnackbar(CreateCaseDrawer);
