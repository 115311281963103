const ReimbursementDetailsEnum = {
  caseId: 'Case Id',
  amountPerUnit: 'Amount Per Unit',
  amountTotal: 'Amount Total',
  approvalDate: 'Approval Date',
  asin: 'ASIN',
  condition: 'Condition',
  currencyUnit: 'Currency Unit',
  fnsku: 'Fnsku',
  quantityReimbursedCash: 'Quantity Reimbursed Cash',
  quantityReimbursedInventory: 'Quantity Reimbursed Inventory',
  quantityReimbursedTotal: 'Quantity Reimbursed Total',
  reason: 'Reason',
  reimbursementId: 'Reimbursement Id',
  sku: 'Sku',
};

export default ReimbursementDetailsEnum;
