const CaseTypeEnum = {
  CARRIER_DAMAGED_RETURN: 'Carrier Damaged Return',
  COMMISSION_DISCREPANCY: 'Commission Discrepancy',
  DAMAGED_INVENTORY: 'Damaged Inventory',
  DESTROYED_INVENTORY: 'Destroyed Without Permission',
  //DIMENSION_DISCREPANCY: 'Dimension Discrepancy',
  FAILED_RETURN: 'Failed Return',
  FULFILLMENT_CENTER_DAMAGED_RETURN: 'Fulfillment Center Damaged Return',
  FULFILLMENT_FEE_DISCREPANCY: 'Fulfillment Fee Discrepancy',
  INBOUND_DISCREPANCY: 'Inbound Discrepancy',
  LOST_INVENTORY: 'Lost Inventory',
  MISSING_REIMBURSEMENT: 'Missing Reimbursement',
  MISSING_RETURN_UNIT: 'Missing Return Unit',
  OVER_REFUNDED: 'Over Refunded',
  RETURNED_INVENTORY_DISCREPANCY: 'Returned Inventory Discrepancy',
  UNDER_REIMBURSED_FAILED_RETURN: 'Under Reimbursed Failed Return',
  UNDER_REIMBURSED_RETURN: 'Under Reimbursed Return',
  UNFULFILLABLE_DAMAGED_INVENTORY: 'Unfulfillable Damaged Inventory',
};

export default CaseTypeEnum;
