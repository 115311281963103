import CaseStateEnum from 'shared/enum/CaseStateEnum';
import MarketplaceEnum from 'shared/enum/MarketplaceEnum';
import httpStatusCodes from 'http-status-codes';
import queryString from 'query-string';
import React, { Component } from 'react';
import Request from 'shared/interceptors/Request';
import { connect } from 'react-redux';
import { formatPercentage } from 'shared/number';
import { Drawer, Table, withSnackbar } from '@rlx-pros/react-foundation';
import { withRouter } from 'react-router-dom';
import { getCurrentMarketplace, getMarketplaceType } from 'shared/marketplace';
import CaseDetails from './dimensionDiscrepanciesTable/CaseDetails';
import IgnoreCaseModal from '../modals/IgnoreCaseModal';
import RecreateCaseModal from '../modals/RecreateCaseModal';
import CreateCaseDrawer from '../cases/casesTable/CreateCaseDrawer';

class DimensionDiscrepanciesTable extends Component {
  constructor(props) {
    super(props);

    let columns = [
      {
        title: 'Updated At',
        field: 'updatedAt',
        editable: 'never',
        hidden: true,
      },
      {
        title: 'ASIN',
        field: 'asin',
        editable: 'never',
        customFilterAndSearch: (search, data) => Table.filter.text('asin', search, data),
        customSort: (a, b) => Table.sort.insensitive('asin', a, b),
      },
      {
        title: 'State',
        field: 'state',
        editable: 'never',
        lookup: {
          AVAILABLE: [CaseStateEnum.AVAILABLE],
          IGNORED: [CaseStateEnum.IGNORED],
          CREATED: [CaseStateEnum.CREATED],
          INVESTIGATE: [CaseStateEnum.INVESTIGATE],
          REIMBURSED: [CaseStateEnum.REIMBURSED],
          UNDER_REIMBURSED: [CaseStateEnum.UNDER_REIMBURSED],
          RESOLVED: [CaseStateEnum.RESOLVED],
          RECREATE: [CaseStateEnum.RECREATE],
          CLOSED: [CaseStateEnum.CLOSED],
        },
        defaultFilter: [CaseStateEnum.AVAILABLE],
        render: (rowData) => CaseStateEnum[rowData.state],
      },
      {
        title: 'Margin Delta(%)',
        field: 'marginDeltaPercent',
        editable: 'never',
        customFilterAndSearch: (search, data) => Table.filter.numeric('marginDeltaPercent', search, data),
        render: (cellValue) => (cellValue.marginDeltaPercent ? formatPercentage(cellValue.marginDeltaPercent) : ''),
      },
      {
        title: 'Volume Delta(%)',
        field: 'volumeDeltaPercent',
        editable: 'never',
        customFilterAndSearch: (search, data) => Table.filter.numeric('volumeDeltaPercent', search, data),
        render: (cellValue) => (cellValue.volumeDeltaPercent ? formatPercentage(cellValue.volumeDeltaPercent) : ''),
      },
      {
        title: 'Amazon Case Id',
        field: 'amazonCaseId',
        editable: 'never',
        customFilterAndSearch: (search, data) => Table.filter.text('amazonCaseId', search, data),
        render: (cellValue) => {
          const { amazonCaseId } = cellValue;
          const marketplaceId = getCurrentMarketplace();
          const marketplaceType = getMarketplaceType(marketplaceId);
          const { caseUrl } = marketplaceType;

          return <a target="_blank" rel="noopener noreferrer" href={`${caseUrl}${amazonCaseId}`}>{amazonCaseId}</a>;
        },
      },
      {
        title: 'Days Until Expires',
        field: 'daysTillCaseExpiration',
        editable: 'never',
        customFilterAndSearch: (search, data) => Table.filter.numeric('daysTillCaseExpiration', search, data),
      },
    ];

    const filters = queryString.parse(props.location.search);
    // If no sorting params are provided, sort by default
    filters.sortColumn = filters.sortColumn || 'marginDeltaPercent';
    filters.sort = filters.sort || 'desc';

    columns = columns.map((column) => {
      if (filters[column.field]) {
        if (column.lookup) {
          const filter = String(filters[column.field]).split(',');
          column.defaultFilter = filter;
        } else {
          column.defaultFilter = filters[column.field];
        }
      }

      if (column.field === filters.sortColumn) {
        column.defaultSort = filters.sort;
      }

      return column;
    });

    this.state = {
      columns,
      selectedCase: {},
      isDetailsDrawerOpen: false,
      isCreateDrawerOpen: false,
      isLoading: true,
      isIgnoreCaseModalOpen: false,
      isIgnoreCaseModalProcessing: false,
      isRecreateCaseModalOpen: false,
      isRecreateCaseModalProcessing: false,
      caseId: null,
      caseType: null,
      discrepancies: [],
    };
  }

  componentDidMount() {
    this.requestData();
  }

  requestData = async () => {
    this.setState({ isLoading: true });
    const url = `${process.env.REACT_APP_ORCHESTRATOR}/dimension-discrepancies`;
    await Request.get(url, MarketplaceEnum)
      .then((result) => {
        this.setState({ discrepancies: result.data.data });
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  toggleDetailsDrawer = () => {
    const { isDetailsDrawerOpen } = this.state;

    this.setState({
      isDetailsDrawerOpen: !isDetailsDrawerOpen,
    });
  }

  toggleCreateDrawer = (isCreateDrawerOpen) => {
    this.setState({ isCreateDrawerOpen });
  };

  ignoreCase = async () => {
    this.setState({
      isIgnoreCaseModalProcessing: true,
    });
    const { caseId } = this.state;
    const { enqueueSnackbar, user } = this.props;

    const url = `${process.env.REACT_APP_ORCHESTRATOR}/ignore-case`;
    await Request.put(url, {
      caseId,
      user: user.attributes.email,
    })
      .then((result) => {
        if (result.status === httpStatusCodes.OK) {
          const { data } = result;
          if (data.result === 1) {
            enqueueSnackbar('Case successfully ignored', {
              variant: 'success',
              persist: false,
            });
          } else {
            throw new Error(data);
          }
        }
      })
      .catch((error) => {
        enqueueSnackbar('Something went wrong while ignoring the selected case.', {
          variant: 'error',
          persist: true,
        });
        console.error(error);
      })
      .finally(() => {
        this.requestData();
        this.setState({
          isIgnoreCaseModalProcessing: false,
          isIgnoreCaseModalOpen: false,
        });
      });
  }

  closeIgnoreCaseModal = () => {
    this.setState({
      isIgnoreCaseModalOpen: false,
    });
  }

  handleCreateCase = async (caseToCreate) => {
    const { state, type, id } = caseToCreate;

    if (state === 'CREATED' || state === 'INVESTIGATE' || state === 'UNDER_REIMBURSED') {
      this.setState({
        caseId: id,
        caseType: type,
        isRecreateCaseModalOpen: true,
      });
    } else {
      this.toggleCreateDrawer(true);

      this.setState({ selectedCase: caseToCreate });
    }
  }

  recreateCase = async () => {
    this.setState({
      isRecreateCaseModalProcessing: true,
    });
    const { caseId, caseType } = this.state;
    const { enqueueSnackbar, user } = this.props;

    const url = `${process.env.REACT_APP_ORCHESTRATOR}/case/recreate`;
    await Request.put(url, {
      id: caseId,
      type: caseType,
      user: user.attributes.email,
    })
      .then((result) => {
        if (result.status === httpStatusCodes.OK) {
          const { data } = result;
          if (data.statusCode !== httpStatusCodes.OK) {
            enqueueSnackbar(data.data.message, {
              variant: 'error',
              persist: true,
            });
          }
        }
      })
      .catch((error) => {
        enqueueSnackbar('Something went wrong while recreating the selected case.', {
          variant: 'error',
          persist: true,
        });
        console.error(error);
      })
      .finally(() => {
        this.setState({
          isRecreateCaseModalProcessing: false,
          isRecreateCaseModalOpen: false,
        });
        this.requestData();
      });
  };

  closeRecreateCaseModal = () => {
    this.setState({
      isRecreateCaseModalOpen: false,
    });
  }

  render() {
    const {
      columns,
      discrepancies,
      isLoading,
      isDetailsDrawerOpen,
      isCreateDrawerOpen,
      selectedCase,
      isIgnoreCaseModalOpen,
      isIgnoreCaseModalProcessing,
      isRecreateCaseModalOpen,
      isRecreateCaseModalProcessing,
    } = this.state;

    const actions = [
      {
        icon: 'launch',
        tooltip: 'View Details',
        position: 'row',
        onClick: (event, rowData) => {
          event.preventDefault();
          this.setState({ selectedCase: rowData });
          this.toggleDetailsDrawer();
        },
      },
      {
        icon: 'add_circle_outline',
        tooltip: 'Create Case',
        onClick: (event, caseToCreate) => {
          event.preventDefault();
          this.handleCreateCase(caseToCreate);
        },
      },
      {
        icon: 'block',
        tooltip: 'Ignore Case',
        onClick: (event, caseToIgnore) => {
          event.preventDefault();
          this.setState({
            caseId: caseToIgnore.id,
            isIgnoreCaseModalOpen: true,
          });
        },
      },
    ];

    return (
      <div>
        <Table
          loading={isLoading}
          columns={columns}
          actions={actions}
          options={{
            filtering: true,
            grouping: false,
            search: false,
            draggable: false,
            sorting: true,
          }}
          data={discrepancies}
          title="Dimension Discrepancies"
        />
        <IgnoreCaseModal
          isOpen={isIgnoreCaseModalOpen}
          isProcessing={isIgnoreCaseModalProcessing}
          closeCallback={this.closeIgnoreCaseModal}
          submitCallback={this.ignoreCase}
        />
        <RecreateCaseModal
          isOpen={isRecreateCaseModalOpen}
          isProcessing={isRecreateCaseModalProcessing}
          closeCallback={this.closeRecreateCaseModal}
          submitCallback={this.recreateCase}
        />
        <Drawer
          anchor="right"
          open={isDetailsDrawerOpen}
          onClose={this.toggleDetailsDrawer}
        >
          <Drawer.Bar close={this.toggleDetailsDrawer} />
          <CaseDetails
            toggleDrawer={this.toggleDetailsDrawer}
            selectedCase={selectedCase}
          />
        </Drawer>
        <CreateCaseDrawer
          anchor="right"
          open={isCreateDrawerOpen}
          caseInfo={selectedCase}
          close={() => this.toggleCreateDrawer(false)}
          refresh={this.requestData}
        />
      </div>
    );
  }
}

DimensionDiscrepanciesTable = withRouter(DimensionDiscrepanciesTable);
const mapStateToProps = (state) => ({
  user: state.global.user,
});

export default connect(
  mapStateToProps,
)(withSnackbar(DimensionDiscrepanciesTable));
