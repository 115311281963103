import CaseTypeEnum from 'shared/enum/CaseTypeEnum';
import moment from 'moment';
import { getCurrentMarketplace, getMarketplaceType } from 'shared/marketplace';
import { formatNumber } from 'shared/number';

// Required Parameters: (startTime, endTime) OR eventDateOption, reportTime, marketplace
// Report Dependent Parameters: adjustmentReason, eventType, fnsku, genericOrderId, orderId, sku, reimbursementId
// TODO: Make sure timestamps are consistent (either stored in PST/PDT or converted correctly)
export const generateReportUrl = (reportType, parameters) => {
  const {
    adjustmentReason,
    endTime,
    eventDateOption,
    eventType,
    fnsku,
    genericOrderId,
    marketplace,
    orderId,
    reimbursementId,
    reportTime,
    sku,
    startTime,
  } = parameters;

  let problemType = '';
  let eventMonthOption = '&eventMonthOption=1';
  let fullFormat = 'MM/DD/YYYY';
  const shortFormat = 'M/D/YY';

  switch (marketplace.countryCode) {
    case 'FR':
      // France
      fullFormat = 'DD/MM/YYYY';
      break;
    case 'UK':
      // United Kingdom
      fullFormat = 'DD/MM/YYYY';
      break;
    case 'DE':
      // Germany
      fullFormat = 'DD.MM.YYYY';
      break;
    case 'JP':
      // TODO: Test and configure Japan
      break;
    case 'CA':
      // Canada (no changes necessary)
      break;
    default:
      // US
      problemType = '&problemType=ALL_DEFECT_TYPES';
      eventMonthOption = '';
      break;
  }

  // Using moment.utc prevents the timestamp from being modified to local timezone
  const startTimeLong = startTime ? moment.utc(startTime).format(fullFormat) : '';
  const startTimeShort = startTime ? moment.utc(startTime).format(shortFormat) : '';
  const endTimeLong = endTime ? moment.utc(endTime).format(fullFormat) : '';
  const endTimeShort = endTime ? moment.utc(endTime).format(shortFormat) : '';
  const reportYear = moment.utc(reportTime).year();

  return (
    `${marketplace.sellerCentralUrl}/gp/ssof/reports/search.html#orderAscending=&recordType=${reportType}`
    + `&noResultType=&inventoryAdjustmentReasonGroup=${adjustmentReason || ''}&merchantSku=${sku || ''}`
    + `&fnSku=${fnsku || ''}&skuType=merchantSku&FnSkuXORMSku=&reimbursementId=${reimbursementId || ''}`
    + `&orderId=${orderId || ''}&genericOrderId=${genericOrderId || ''}&asin=&lpn=&shipmentId=${problemType}`
    + `&hazmatStatus=&inventoryEventTransactionType=${eventType || ''}&fulfillmentCenterId=&transactionItemId=`
    + `&inventoryAdjReasonGroups=${adjustmentReason || ''}&eventDateOption=${eventDateOption || 0}`
    + `&fromDate=${encodeURIComponent(startTimeLong)}&toDate=${encodeURIComponent(endTimeLong)}`
    + `&startDate=${encodeURIComponent(startTimeShort)}&endDate=${encodeURIComponent(endTimeShort)}${eventMonthOption}`
    + `&fromMonth=1&fromYear=${reportYear}&toMonth=1&toYear=${reportYear}&startMonth=&startYear=&endMonth=&endYear=`
  );
};

export default class CreateCaseInstructions {
  constructor(caseInfo, caseDetails) {
    this.caseInfo = caseInfo;
    this.caseDetails = caseDetails;

    // TODO: Get marketplaceId from session (may be done in one call depending on implementation)
    const marketplaceId = getCurrentMarketplace();
    this.marketplace = getMarketplaceType(marketplaceId);
  }

  static getInstructions = (caseTypeEnum) => {
    if (CaseTypeEnum[caseTypeEnum] === CaseTypeEnum.INBOUND_DISCREPANCY) {
      return 'Amazon requires this case to be created in the "Shipments" > "Reconcile" tab in Seller Central, '
        + 'so please follow the link below to create this case:';
    }

    return (
      'To create a case follow the link below, at the bottom under "Need more help?" click "Get Support" > '
      + '"Selling on Amazon" > "Fulfillment by Amazon" > "Investigate Other FBA Issues" > "Other FBA Issue", '
      + 'then copy/paste the fields below and submit the form:'
    );
  };

  getUrl = () => {
    const { typeEnum } = this.caseInfo;

    if (CaseTypeEnum[typeEnum] === CaseTypeEnum.INBOUND_DISCREPANCY) {
      return `${this.marketplace.inboundShipmentUrl}${this.caseDetails.shipmentId}/summary/discrepancies`;
    }

    return this.marketplace.contactUrl;
  };

  getDescription = () => {
    // These functions return an object with keys { contactReason, description }
    const descriptions = {
      CARRIER_DAMAGED_RETURN: this.carrierDamagedReturn,
      COMMISSION_DISCREPANCY: this.commissionDiscrepancy,
      DAMAGED_INVENTORY: this.damagedInventory,
      DESTROYED_INVENTORY: this.destroyedInventory,
      //DIMENSION_DISCREPANCY: this.dimensionDiscrepancy,
      FAILED_RETURN: this.failedReturn,
      FULFILLMENT_CENTER_DAMAGED_RETURN: this.fulfillmentCenterDamagedReturn,
      FULFILLMENT_FEE_DISCREPANCY: this.fulfillmentFeeDiscrepency,
      LOST_INVENTORY: this.lostInventory,
      MISSING_REIMBURSEMENT: this.missingReimbursement,
      MISSING_RETURN_UNIT: this.missingReturnUnit,
      OVER_REFUNDED: this.overRefunded,
      RETURNED_INVENTORY_DISCREPANCY: this.returnedInventoryDiscrepancy,
      UNDER_REIMBURSED_FAILED_RETURN: this.underReimbursedFailedReturn,
      UNDER_REIMBURSED_RETURN: this.underReimbursedReturn,
      UNFULFILLABLE_DAMAGED_INVENTORY: this.unfulfillableDamagedInventory,
    };

    const { typeEnum } = this.caseInfo;

    const description = descriptions[typeEnum];

    return description ? description() : '';
  };

  paymentsReport = (orderId) => (
    `${this.marketplace.sellerCentralUrl}/gp/payments-account/view-transactions.html?view=search&orderId=${orderId}`
  );

  carrierDamagedReturn = () => {
    const {
      fnsku,
      orderId,
      reimbursed,
      returned,
    } = this.caseDetails;
    const {
      startTime,
      reportTime,
    } = this.caseInfo;

    const contactReason = `Carrier Damaged Return - ${orderId}`;

    const returnReportUrl = generateReportUrl('CUSTOMER_RETURNS', {
      startTime,
      reportTime,
      genericOrderId: orderId,
      marketplace: this.marketplace,
      endTime: reportTime,
    });
    const reimbursementsReportUrl = generateReportUrl('REIMBURSEMENTS', {
      orderId,
      reportTime,
      startTime,
      marketplace: this.marketplace,
      endTime: reportTime,
    });

    const description = (
      `Please see order id ${orderId} and fnsku ${fnsku}, our records show that the customer returned ${returned} unit(s) `
      + `marked as carrier damaged, and you reimbursed ${reimbursed} unit(s). According to Amazon guidelines, since this `
      + 'unit(s) was damaged by an Amazon partnered carrier while on route to the customer Amazon should cover the damage. '
      + 'Please investigate and reimburse us the difference. Also, please remove these damaged units from the warehouse or '
      + 'move them from damaged to fulfillable. Here are the reports for reference, thank you.\n\n'
      + 'Returns:\n'
      + `${returnReportUrl}\n\n`
      + 'Reimbursed:\n'
      + `${reimbursementsReportUrl}`
    );

    return {
      contactReason,
      description,
    };
  };

  commissionDiscrepancy = () => {
    const {
      amazonOrderIds,
      sku,
    } = this.caseDetails;
    let {
      commission,
      chargedCommission,
      difference,
    } = this.caseDetails;

    const contactReason = `Commission Discrepancy - ${sku}`;

    const currency = this.marketplace.currencySymbol;

    commission = `${currency}${formatNumber(commission, 2)}`;
    chargedCommission = `${currency}${formatNumber(chargedCommission, 2)}`;
    difference = `${currency}${formatNumber(difference, 2)}`;

    // TODO: When saving amazonOrderIds, replace <br> with \n
    const orderIds = amazonOrderIds ? amazonOrderIds.replace(/<br>/g, '\n') : '';
    let inventoryUrl = '/hz/inventory/?tbla_myitable=sort:%7B%22sortOrder%22%3A%22DESCENDING%22%2C%22'
      + 'sortedColumnId%22%3A%22date%22%7D;search:';

    if (this.marketplace.countryCode === 'UK' || this.marketplace.countryCode === 'DE') {
      inventoryUrl = '/inventory/view/FBA?tbla_myitable=sort:%7B%22sortOrder%22%3A%22DESCENDING%22%2C%22'
        + 'sortedColumnId%22%3A%22date%22%7D;search:';
    }

    const inventoryReportUrl = `${this.marketplace.sellerCentralUrl}${inventoryUrl}${sku};pagination:1;`;

    const description = (
      `Please see sku ${sku}, according to the Fee Preview report the estimated Commission (Referral Fee) per unit is `
      + `${commission} but our records show that we were charged ${chargedCommission} per unit on each of the orders `
      + `below, this is a difference of ${difference} per unit. Please investigate and reimburse us the difference, or `
      + 'explain why we were charged more, thank you.\n\n'
      + `${orderIds}\n\n`
      + 'Here are the reports for reference.\n\n'
      + 'Manage Inventory:\n'
      + `${inventoryReportUrl}`
    );

    return {
      contactReason,
      description,
    };
  };

  damagedInventory = () => {
    const {
      fnsku,
      damaged,
      reimbursed,
    } = this.caseDetails;

    const {
      startTime,
      endTime,
      reportTime,
    } = this.caseInfo;

    const contactReason = `Warehouse Damaged Inventory - ${fnsku}`;

    const damagedReportUrl = generateReportUrl('INVENTORY_ADJUSTMENT', {
      fnsku,
      startTime,
      endTime,
      reportTime,
      marketplace: this.marketplace,
      adjustmentReason: 'Damaged',
    });
    const reimbursedReportUrl = generateReportUrl('REIMBURSEMENTS', {
      fnsku,
      startTime,
      reportTime,
      marketplace: this.marketplace,
      endTime: reportTime,
    });

    const description = (
      `Please see fnsku ${fnsku}, our records show that you damaged ${damaged} unit(s) with reason `
      + `"Damaged at Amazon Fulfillment Center", and reimbursed ${reimbursed} unit(s). `
      + 'Please investigate and reimburse us the difference. Here are the reports for reference, thank you.\n\n'
      + 'Damaged:\n'
      + `${damagedReportUrl}\n\n`
      + 'Reimbursed:\n'
      + `${reimbursedReportUrl}`
    );

    return {
      contactReason,
      description,
    };
  };

  destroyedInventory = () => {
    const {
      destroyed,
      fnsku,
      reimbursed,
    } = this.caseDetails;
    const {
      startTime,
      endTime,
      reportTime,
    } = this.caseInfo;

    const contactReason = `Destroyed Without Permission - ${fnsku}`;

    const destroyedReportUrl = generateReportUrl('INVENTORY_ADJUSTMENT', {
      fnsku,
      startTime,
      endTime,
      reportTime,
      marketplace: this.marketplace,
      adjustmentReason: 'Destroyed',
    });
    const reimbursedReportUrl = generateReportUrl('REIMBURSEMENTS', {
      fnsku,
      startTime,
      reportTime,
      marketplace: this.marketplace,
      endTime: reportTime,
    });

    const description = (
      `Please see fnsku ${fnsku}, our records show that you destroyed ${destroyed} unit(s) without our permission, `
      + `and reimbursed ${reimbursed} unit(s). Please reimburse us the difference or explain why the units were `
      + 'destroyed. Here are the reports for reference, thank you.\n\n'
      + 'Destroyed:\n'
      + `${destroyedReportUrl}\n\n`
      + 'Reimbursed:\n'
      + `${reimbursedReportUrl}`
    );

    return {
      contactReason,
      description,
    };
  };

  /* dimensionDiscrepancy = () => {
    const {
      asin,
      itemPackageWeight,
      longestSide,
      medianSide,
      previousItemPackageWeight,
      previousLongestSide,
      previousMedianSide,
      previousShortestSide,
      shortestSide,
      unitOfDimension,
      unitOfWeight,
    } = this.caseDetails;

    const contactReason = `Dimension Discrepancy - ${asin}`;

    const description = (
      `Our audit shows that you changed the dimensions/weight of ASIN ${asin} from the previous dimensions of `
      + `${previousLongestSide} x ${previousMedianSide} x ${previousShortestSide} ${unitOfDimension} and weight of `
      + `${previousItemPackageWeight} ${unitOfWeight} to ${longestSide} x ${medianSide} x ${shortestSide} `
      + `${unitOfDimension} and weight of ${itemPackageWeight} ${unitOfWeight}. We believe this was an error and would `
      + 'like the dimensions/weight to be changed back to our previous values, thank you.'
    );

    return {
      contactReason,
      description,
    };
  } */

  failedReturn = () => {
    const {
      amazonOrderId,
      refunded,
      reimbursed,
      returned,
      sellerOrderId,
      sku,
    } = this.caseDetails;

    const {
      startTime,
      reportTime,
    } = this.caseInfo;

    const contactReason = `Failed Customer Return - ${sellerOrderId}`;

    const orderDetailsUrl = `${this.marketplace.sellerCentralUrl}/orders-v3/order/${sellerOrderId}`;
    const returnsReportUrl = generateReportUrl('CUSTOMER_RETURNS', {
      sku,
      startTime,
      reportTime,
      marketplace: this.marketplace,
      genericOrderId: sellerOrderId,
      endTime: reportTime,
    });
    const reimbursementReportUrl = generateReportUrl('REIMBURSEMENTS', {
      sku,
      startTime,
      reportTime,
      marketplace: this.marketplace,
      orderId: amazonOrderId,
      endTime: reportTime,
    });

    const description = (
      `Please see order id ${sellerOrderId} and sku ${sku}, our records show that you refunded ${refunded} unit(s), `
      + `the customer returned ${returned} unit(s), and you reimbursed ${reimbursed} unit(s). `
      + 'Please investigate and reimburse us the difference. Here are the reports for reference, thank you.\n\n'
      + 'Order Details:\n'
      + `${orderDetailsUrl}\n\n`
      + 'Returns:\n'
      + `${returnsReportUrl}\n\n`
      + 'Reimbursed:\n'
      + `${reimbursementReportUrl}`
    );

    return {
      contactReason,
      description,
    };
  };

  fulfillmentCenterDamagedReturn = () => {
    const {
      fnsku,
      orderId,
      reason,
      reimbursed,
      returned,
    } = this.caseDetails;
    const {
      startTime,
      reportTime,
    } = this.caseInfo;

    const contactReason = `Fulfillment Center Damaged Return - ${orderId}`;

    const returnsReportUrl = generateReportUrl('CUSTOMER_RETURNS', {
      startTime,
      reportTime,
      marketplace: this.marketplace,
      genericOrderId: orderId,
      endTime: reportTime,
    });
    const reimbursementsReportUrl = generateReportUrl('REIMBURSEMENTS', {
      startTime,
      orderId,
      reportTime,
      marketplace: this.marketplace,
      endTime: reportTime,
    });

    const description = (
      `Please see order id ${orderId} and fnsku ${fnsku}, our records show that the customer returned ${returned} unit(s) `
      + `marked as "DAMAGED" with a reason of "${reason}" which was returned to inventory, and you reimbursed ${reimbursed} `
      + 'unit(s). According to Amazon guidelines, since this unit(s) was damaged or undelivered at an Amazon fulfillment '
      + 'center, Amazon should cover the damage. Please investigate and reimburse us the difference. Here are the reports '
      + 'for reference, thank you.\n\n'
      + 'Returns:\n'
      + `${returnsReportUrl}\n\n`
      + 'Reimbursed:\n'
      + `${reimbursementsReportUrl}`
    );

    return {
      contactReason,
      description,
    };
  };

  fulfillmentFeeDiscrepency = () => {
    const {
      amazonOrderIds,
      sku,
    } = this.caseDetails;
    let {
      chargedFulfillmentFee,
      difference,
      fulfillmentFee,
    } = this.caseDetails;

    const contactReason = `Fulfillment Fee Discrepancy - ${sku}`;

    const currency = this.marketplace.currencySymbol;
    chargedFulfillmentFee = `${currency}${formatNumber(chargedFulfillmentFee, 2)}`;
    difference = `${currency}${formatNumber(difference, 2)}`;
    fulfillmentFee = `${currency}${formatNumber(fulfillmentFee, 2)}`;

    let inventoryUrl = '/hz/inventory/?tbla_myitable=sort:%7B%22sortOrder%22%3A%22DESCENDING%22%2C%22'
      + 'sortedColumnId%22%3A%22date%22%7D;search:';

    if (this.marketplace.countryCode === 'UK' || this.marketplace.countryCode === 'DE') {
      inventoryUrl = '/inventory/view/FBA?tbla_myitable=sort:%7B%22sortOrder%22%3A%22DESCENDING%22%2C%22'
        + 'sortedColumnId%22%3A%22date%22%7D;search:';
    }

    const inventoryReportUrl = `${this.marketplace.sellerCentralUrl}${inventoryUrl}${sku};pagination:1;`;

    // TODO: When saving amazonOrderIds, replace <br> with \n
    const orderIds = amazonOrderIds ? amazonOrderIds.replace(/<br>/g, '\n') : '';

    const description = (
      `Please see sku ${sku}, according to the Fee Preview report the expected FBA Fulfillment fee per unit is `
      + `${fulfillmentFee} but our records show that we were charged ${chargedFulfillmentFee} per unit on each of the `
      + `orders below, this is a difference of ${difference} per unit. Please investigate and reimburse us the `
      + 'difference, or explain why we were charged more, thank you.\n\n'
      + `${orderIds}\n\n`
      + 'Here are the reports for reference.\n\n'
      + 'Manage Inventory:\n'
      + `${inventoryReportUrl}`
    );

    return {
      contactReason,
      description,
    };
  };

  lostInventory = () => {
    const {
      fnsku,
      lost,
      found,
      reimbursed,
    } = this.caseDetails;
    const {
      startTime,
      endTime,
      reportTime,
    } = this.caseInfo;

    const contactReason = `Lost Inventory - ${fnsku}`;

    const lostReportUrl = generateReportUrl('INVENTORY_ADJUSTMENT', {
      fnsku,
      startTime,
      endTime,
      reportTime,
      marketplace: this.marketplace,
      adjustmentReason: 'Lost',
    });
    const foundReportUrl = generateReportUrl('INVENTORY_ADJUSTMENT', {
      fnsku,
      startTime,
      endTime,
      reportTime,
      marketplace: this.marketplace,
      adjustmentReason: 'Found',
    });
    const reimbursedReportUrl = generateReportUrl('REIMBURSEMENTS', {
      fnsku,
      startTime,
      reportTime,
      marketplace: this.marketplace,
      endTime: reportTime,
    });

    const description = (
      `Please see fnsku ${fnsku}, our records show that you lost ${lost} unit(s), found ${found} unit(s), `
      + `and reimbursed ${reimbursed} unit(s). Please investigate and reimburse us the difference. `
      + 'Here are the reports for reference, thank you.\n\n'
      + 'Lost:\n'
      + `${lostReportUrl}\n\n`
      + 'Found:\n'
      + `${foundReportUrl}\n\n`
      + 'Reimbursed:\n'
      + `${reimbursedReportUrl}`
    );

    return {
      contactReason,
      description,
    };
  };

  missingReimbursement = () => {
    const {
      orderId,
      sku,
    } = this.caseDetails;
    const {
      startTime,
      reportTime,
    } = this.caseInfo;

    const contactReason = `Missing Reimbursement - ${orderId}`;

    const orderReturnsUrl = generateReportUrl('CUSTOMER_RETURNS', {
      startTime,
      reportTime,
      marketplace: this.marketplace,
      genericOrderId: orderId,
      endTime: reportTime,
    });
    const reimbursementReport = generateReportUrl('REIMBURSEMENTS', {
      orderId,
      startTime,
      reportTime,
      marketplace: this.marketplace,
      endTime: reportTime,
    });

    const description = (
      `Please see order id ${orderId} and sku ${sku}, our records show that the customer return had a status of `
      + '"Reimbursed", but we cannot locate a reimbursement in the report. Please investigate and reimburse us the '
      + 'appropriate value. Here are the reports for reference, thank you.\n\n'
      + 'Returns:\n'
      + `${orderReturnsUrl}\n\n`
      + 'Reimbursed:\n'
      + `${reimbursementReport}`
    );

    return {
      contactReason,
      description,
    };
  };

  missingReturnUnit = () => {
    const {
      fulfillmentCenterId,
      orderId,
      sku,
    } = this.caseDetails;
    const {
      startTime,
      endTime,
      reportTime,
    } = this.caseInfo;

    const contactReason = `Missing Return Unit - ${orderId}`;

    const orderReturnsReportUrl = generateReportUrl('CUSTOMER_RETURNS', {
      startTime,
      reportTime,
      marketplace: this.marketplace,
      genericOrderId: orderId,
      endTime: reportTime,
    });
    const unitsReturnedReportUrl = generateReportUrl('INVENTORY_EVENT_DETAIL', {
      sku,
      startTime,
      endTime,
      reportTime,
      marketplace: this.marketplace,
      eventType: 'CustomerReturns',
    });
    const reimbursementsReportUrl = generateReportUrl('REIMBURSEMENTS', {
      orderId,
      startTime,
      reportTime,
      marketplace: this.marketplace,
      endTime: reportTime,
    });

    const description = (
      `Please see order id ${orderId} and sku ${sku} in fulfillment center ${fulfillmentCenterId}, our records show `
      + 'that the customer return had a status of "Unit returned to inventory", but we cannot locate this returned unit in '
      + 'a "Sellable" disposition in the "Inventory Event Detail" report, and we were not reimbursed. Please investigate '
      + 'and reimburse us the appropriate value. Here are the reports for reference, thank you.\n\n'
      + 'Returns:\n'
      + `${orderReturnsReportUrl}\n\n`
      + 'Inventory Event Detail:\n'
      + `${unitsReturnedReportUrl}\n\n`
      + 'Reimbursed:\n'
      + `${reimbursementsReportUrl}`
    );

    return {
      contactReason,
      description,
    };
  };

  overRefunded = () => {
    const {
      amazonOrderId,
      sellerOrderId,
      sku,
    } = this.caseDetails;
    let {
      goodwill,
      reimbursed,
    } = this.caseDetails;

    const {
      startTime,
      reportTime,
    } = this.caseInfo;

    const currency = this.marketplace.currencySymbol;
    goodwill = `${currency}${formatNumber(goodwill, 2)}`;
    reimbursed = `${currency}${formatNumber(reimbursed, 2)}`;

    const contactReason = `Customer Over Refunded - ${sellerOrderId}`;

    const orderPaymentsUrl = this.paymentsReport(sellerOrderId);
    const reimbursementReportUrl = generateReportUrl('REIMBURSEMENTS', {
      sku,
      startTime,
      reportTime,
      marketplace: this.marketplace,
      orderId: amazonOrderId,
      endTime: reportTime,
    });

    const description = (
      `Please see order id ${sellerOrderId} and sku ${sku}, our records show that the customer returned the item which `
      + `was returned to inventory, but you refunded the customer an extra concession (goodwill) of ${goodwill}, and `
      + `you reimbursed ${reimbursed}. Please investigate and reimburse us the difference. Here are the reports for `
      + 'reference, thank you.\n\n'
      + 'Order Payments:\n'
      + `${orderPaymentsUrl}\n\n`
      + 'Reimbursed:\n'
      + `${reimbursementReportUrl}`
    );

    return {
      contactReason,
      description,
    };
  };

  returnedInventoryDiscrepancy = () => {
    const {
      fnsku,
      orderId,
      returned,
    } = this.caseDetails;
    const {
      startTime,
      endTime,
      reportTime,
    } = this.caseInfo;

    const contactReason = `Returned Inventory Discrepancy - ${orderId}`;

    const paymentsReportUrl = this.paymentsReport(orderId);
    const returnsReportUrl = generateReportUrl('CUSTOMER_RETURNS', {
      startTime,
      reportTime,
      marketplace: this.marketplace,
      genericOrderId: orderId,
      endTime: reportTime,
    });
    const shipmentSalesReportUrl = generateReportUrl('SHIPMENT_SALES', {
      orderId,
      startTime,
      endTime,
      reportTime,
      marketplace: this.marketplace,
    });
    const reimbursementsReportUrl = generateReportUrl('REIMBURSEMENTS', {
      orderId,
      startTime,
      reportTime,
      marketplace: this.marketplace,
      endTime: reportTime,
    });

    const description = (
      `Please see order id ${orderId} and fnsku ${fnsku}, our records show that the customer returned ${returned} unit(s) `
      + 'which you refunded and were supposed to return the unit(s) to our inventory. However, the fnsku shown as '
      + 'returned by the customer is not the same fnsku that was ordered and shipped, so it was never returned to our '
      + 'inventory with the proper fnsku that was sold, and you reimbursed {units reimbursed} unit(s). Please '
      + 'investigate and reimburse us the difference. Here are the reports for reference, thank you.\n\n'
      + 'Returns:\n'
      + `${returnsReportUrl}\n\n`
      + 'Order Payments:\n'
      + `${paymentsReportUrl}\n\n`
      + 'Shipment Sales:\n'
      + `${shipmentSalesReportUrl}\n\n`
      + 'Reimbursed:\n'
      + `${reimbursementsReportUrl}`
    );

    return {
      contactReason,
      description,
    };
  };

  underReimbursedFailedReturn = () => {
    const {
      amazonOrderId,
      sellerOrderId,
      sku,
    } = this.caseDetails;
    let {
      refunded,
      reimbursed,
    } = this.caseDetails;
    const {
      startTime,
      reportTime,
    } = this.caseInfo;

    const contactReason = `Under Reimbursed Failed Customer Return - ${sellerOrderId}`;

    const currency = this.marketplace.currencySymbol;
    refunded = `${currency}${formatNumber(refunded, 2)}`;
    reimbursed = `${currency}${formatNumber(reimbursed, 2)}`;

    const paymentsReportUrl = this.paymentsReport(sellerOrderId);
    const returnsReportUrl = generateReportUrl('CUSTOMER_RETURNS', {
      sku,
      startTime,
      reportTime,
      marketplace: this.marketplace,
      genericOrderId: sellerOrderId,
      endTime: reportTime,
    });
    const reimbursementsReportUrl = generateReportUrl('REIMBURSEMENTS', {
      sku,
      startTime,
      reportTime,
      marketplace: this.marketplace,
      orderId: amazonOrderId,
      endTime: reportTime,
    });

    const description = (
      `Please see order id ${sellerOrderId} and sku ${sku}, our records show that you refunded the customer `
      + `${refunded} from our account, the customer never returned the item(s), and you only reimbursed ${reimbursed}. `
      + 'Since it has been more than 45 days, please investigate and reimburse us the difference, or explain why we '
      + 'were reimbursed less. Here are the reports for reference, thank you.\n\n'
      + 'Order Payments:\n'
      + `${paymentsReportUrl}\n\n`
      + 'Returns:\n'
      + `${returnsReportUrl}\n\n`
      + 'Reimbursed:\n'
      + `${reimbursementsReportUrl}`
    );

    return {
      contactReason,
      description,
    };
  };

  underReimbursedReturn = () => {
    const {
      amazonOrderId,
      sellerOrderId,
      sku,
    } = this.caseDetails;
    let {
      refunded,
      reimbursed,
    } = this.caseDetails;
    const {
      startTime,
      reportTime,
    } = this.caseInfo;

    const contactReason = `Under Reimbursed Customer Return - ${sellerOrderId}`;

    const currency = this.marketplace.currencySymbol;
    refunded = `${currency}${formatNumber(refunded, 2)}`;
    reimbursed = `${currency}${formatNumber(reimbursed, 2)}`;

    const paymentsReportUrl = this.paymentsReport(sellerOrderId);
    const returnsReportUrl = generateReportUrl('CUSTOMER_RETURNS', {
      sku,
      startTime,
      reportTime,
      marketplace: this.marketplace,
      genericOrderId: sellerOrderId,
      endTime: reportTime,
    });
    const reimbursementsReportUrl = generateReportUrl('REIMBURSEMENTS', {
      sku,
      startTime,
      reportTime,
      marketplace: this.marketplace,
      orderId: amazonOrderId,
      endTime: reportTime,
    });

    const description = (
      `Please see order id ${sellerOrderId} and sku ${sku}, our records show that you refunded the customer ${refunded} `
      + 'from our account, the customer returned the item(s) which was reimbursed, but you only reimbursed '
      + `${reimbursed}. Since it has been more than 45 days, please investigate and reimburse us the difference, or `
      + 'explain why we were reimbursed less. Here are the reports for reference, thank you.\n\n'
      + 'Order Payments:\n'
      + `${paymentsReportUrl}\n\n`
      + 'Returns:\n'
      + `${returnsReportUrl}\n\n`
      + 'Reimbursed:\n'
      + `${reimbursementsReportUrl}`
    );

    return {
      contactReason,
      description,
    };
  };

  unfulfillableDamagedInventory = () => {
    const {
      damaged,
      fnsku,
      fulfillmentCenterId,
      maxSnapshotDate,
      reimbursed,
    } = this.caseDetails;

    const {
      startTime,
      endTime,
      reportTime,
    } = this.caseInfo;

    const contactReason = `Unfulfillable Damaged Inventory - ${fnsku} in ${fulfillmentCenterId}`;

    const inventoryReportUrl = generateReportUrl('INVENTORY_SNAPSHOT', {
      fnsku,
      startTime,
      endTime,
      reportTime,
      marketplace: this.marketplace,
    });
    const reimbursedReportUrl = generateReportUrl('REIMBURSEMENTS', {
      fnsku,
      startTime: maxSnapshotDate,
      endTime: reportTime,
      reportTime,
      marketplace: this.marketplace,
    });

    const description = (
      `Please see fnsku ${fnsku} in warehouse ${fulfillmentCenterId}, our records show that you damaged ${damaged} `
      + `unit(s), and reimbursed ${reimbursed} unit(s). Since the unit(s) have been damaged for more than 30 `
      + 'consecutive days, please investigate and reimburse us the difference, or if a reimbursement has already been '
      + 'issued, please remove these damaged units from the warehouse or move them from damaged to fulfillable. Here are '
      + 'the reports for reference, thank you.\n\n'
      + 'Damaged:\n'
      + `${inventoryReportUrl}\n\n`
      + 'Reimbursed:\n'
      + `${reimbursedReportUrl}`
    );

    return {
      contactReason,
      description,
    };
  };
}
