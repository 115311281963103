import React, { Component } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  MetricCarousel,
  MetricCardClickable,
  nivoTheme,
} from '@rlx-pros/react-foundation';
import {
  formatCurrency,
  formatNumber,
} from 'shared/number';
import withSummary from './withSummary';

class Carousel extends Component {
  render() {
    const {
      isSummaryLoading,
      summary,
      onMetricSelect,
    } = this.props;
    const carouselMetrics = summary.carouselMetrics || {};
    const skeleton = <Skeleton variant="text" height={36}/>;

    return (
      <MetricCarousel>
        <MetricCardClickable
          title="Cases Available"
          metric={!isSummaryLoading ? formatCurrency(carouselMetrics.caseAvailableTotal) : skeleton}
          onClick={() => onMetricSelect('casesAvailable')}
          tooltip="The total cases available within the current marketplace."
          color={nivoTheme[0]}
          change={!isSummaryLoading ? formatNumber(carouselMetrics.caseAvailableCount) : skeleton}
        />
        <MetricCardClickable
          title="Cases Reimbursed"
          metric={!isSummaryLoading ? formatCurrency(carouselMetrics.caseReimbursedTotal) : skeleton}
          onClick={() => onMetricSelect('casesReimbursed')}
          tooltip="The total cases reimbursed within the current marketplace."
          color={nivoTheme[1]}
          change={!isSummaryLoading ? formatNumber(carouselMetrics.caseReimbursedCount) : skeleton}
        />
        {/* <MetricCardClickable
          title="Dimension Discrepancies"
          metric={!isSummaryLoading ? formatNumber(carouselMetrics.dimensionDiscrepancyAvailableCount) : skeleton}
          onClick={() => onMetricSelect('dimensionDiscrepanciesAvailable')}
          tooltip="The total number of available Dimension Discrepancy cases."
          color={nivoTheme[2]}
          change={!isSummaryLoading ? 'Available' : skeleton}
        />
        <MetricCardClickable
          title="Dimension Discrepancies"
          metric={!isSummaryLoading ? formatNumber(carouselMetrics.dimensionDiscrepancyResolvedCount) : skeleton}
          onClick={() => onMetricSelect('dimensionDiscrepanciesResolved')}
          tooltip="The total number of resolved Dimension Discrepancy cases."
          color={nivoTheme[3]}
          change={!isSummaryLoading ? 'Resolved' : skeleton}
        /> */}
        <MetricCardClickable
          title="Expires Soon"
          metric={!isSummaryLoading ? formatCurrency(carouselMetrics.expiresSoonEstimatedTotal) : skeleton}
          onClick={() => onMetricSelect('expiresSoonEstimated')}
          tooltip="The total cases expiring soon within the current marketplace."
          color={nivoTheme[4]}
          change={!isSummaryLoading ? formatNumber(carouselMetrics.expiresSoonEstimatedCount) : skeleton}
        />
        <MetricCardClickable
          title="Under Reimbursed"
          metric={!isSummaryLoading ? formatCurrency(carouselMetrics.underReimbursedEstimatedTotal) : skeleton}
          onClick={() => onMetricSelect('underReimbursedEstimated')}
          tooltip="The total opened cases which were under reimbursed within the current marketplace."
          color={nivoTheme[5]}
          change={!isSummaryLoading ? formatNumber(carouselMetrics.underReimbursedEstimatedCount) : skeleton}
        />
        <MetricCardClickable
          title="Investigate"
          metric={!isSummaryLoading ? formatCurrency(carouselMetrics.investigateEstimatedTotal) : skeleton}
          onClick={() => onMetricSelect('investigateEstimated')}
          tooltip="The total cases which need investigation within the current marketplace."
          color={nivoTheme[6]}
          change={!isSummaryLoading ? formatNumber(carouselMetrics.investigateEstimatedCount) : skeleton}
        />
      </MetricCarousel>
    );
  }
}

Carousel = withSummary(Carousel);

export default Carousel;
