import React, { Component } from 'react';
import { Headerbar as FoundationHeaderbar } from '@rlx-pros/react-foundation';
import { connect } from 'react-redux';

class Headerbar extends Component {
  accountLinks = [
    {
      title: 'Sign Out',
      href: '/sign-out',
    },
  ];

  links = [
    {
      title: 'Dashboard',
      href: '/dashboard',
      exact: true,
    },
    {
      title: 'Cases',
      href: '/cases',
      exact: true,
    },
    /* {
      title: 'Dimension Discrepancies',
      href: '/dimension-discrepancies',
      exact: true,
    }, */
  ];

  render() {
    const { client, user } = this.props;

    return (
      <FoundationHeaderbar>
        <FoundationHeaderbar.Spacer />
        <FoundationHeaderbar.Links
          links={this.links}
        />
        <FoundationHeaderbar.Applications
          domain={process.env.REACT_APP_COOKIE_DOMAIN}
          applications={client.applications || []}
        />
        <FoundationHeaderbar.Account
          links={this.accountLinks}
          company={client.name || ''}
          username={user.username || ''}
        />
      </FoundationHeaderbar>
    );
  }
}

const mapStateToProps = (state) => ({
  client: state.global.client,
  user: state.global.user,
});

export default connect(
  mapStateToProps,
)(Headerbar);
