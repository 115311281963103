import React, { Component } from 'react';
import { Button, Modal } from '@rlx-pros/react-foundation';

class RecreateCaseModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isProcessing: false,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      isOpen: nextProps.isOpen,
      isProcessing: nextProps.isProcessing,
    };
  }

  close = () => {
    const { closeCallback } = this.props;
    closeCallback();
  }

  recreate = () => {
    const { submitCallback } = this.props;
    submitCallback();
  }

  render() {
    const { isOpen, isProcessing } = this.state;
    return (
      <Modal
        open={isOpen}
        onClose={this.close}
        contentLabel="Recreate Case"
      >
        <div>
          <p>Are you sure you want to change the state of this case to &quot;recreate&quot;?</p>
          <br />
          <p>
            You should only change the state of the case to &quot;recreate&quot; if Amazon has closed
            the case and you would like to recreate it because:
          </p>
          <div>
            <ol style={{ marginLeft: '1.0rem' }}>
              <li style={{ marginTop: '0.25rem' }}>
                Amazon has replied that no reimbursement is due, but you are sure it is due.
              </li>
              <li style={{ marginTop: '0.25rem' }}>
                Amazon has requested additional information and you would like to supply it.
              </li>
              <li style={{ marginTop: '0.25rem' }}>
                You accidentally saved the wrong Amazon Case ID with this case so it was
                never actually created in this marketplace.
              </li>
              <li style={{ marginTop: '0.25rem' }}>
                Amazon has replied that a reimbursement has been issued, but you cannot
                locate the reimbursement ID in the Reimbursements report and it has been more than 5 days since.
              </li>
            </ol>
          </div>
          <br />
        </div>
        <Button.Group position="right">
          <Button outlined disabled={isProcessing} onClick={this.close}>Cancel</Button>
          <Button color="danger" loading={isProcessing} onClick={this.recreate}>Recreate</Button>
        </Button.Group>
      </Modal>
    );
  }
}

export default RecreateCaseModal;
