import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import ReduxThunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { createLogger } from 'redux-logger';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { reducer as formReducer } from 'redux-form';
import App from 'redux/App';
import { Global } from '@rlx-pros/react-foundation';

const rootReducer = combineReducers({
  app: App,
  global: Global,
  form: formReducer,
});

const middleware = [ReduxThunk];

if (process.env.REACT_APP_DEBUG === 'true') {
  const logger = createLogger({
    // Collapse actions by default unless they caused an error.
    collapsed: (getState, action, logEntry) => !logEntry.error,

    // If the logs get annoying we can filter them out by action.type.
    // predicate: (getState, action) => action.type !== AUTH_REMOVE_TOKEN
  });

  middleware.push(logger);
}

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['form'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, {}, applyMiddleware(...middleware));
export const persistor = persistStore(store);
