import React, { Component } from 'react';
import { Section, withSnackbar } from '@rlx-pros/react-foundation';
import Request from 'shared/interceptors/Request';
import StatusEnum from 'shared/enum/StatusEnum';
import Carousel from './Carousel';
import Insights from './Insights';
import SummaryContext from './SummaryContext';

class Summary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isMounted: true,
      summary: {
        isSummaryLoading: true,
      },
      selectedMetrics: [],
    };
  }

  componentDidMount() {
    this.requestData();
  }

  componentWillUnmount() {
    this.setState({
      isMounted: false,
    });
  }

  onMetricSelect = (metric) => {
    let path;
    switch (metric) {
      case 'casesAvailable': {
        path = './cases?state=AVAILABLE';
        break;
      }
      case 'casesReimbursed': {
        path = './cases?state=REIMBURSED,UNDER_REIMBURSED&sortColumn=updatedAt&sort=desc';
        break;
      }
      case 'dimensionDiscrepanciesAvailable': {
        path = './dimension-discrepancies?state=AVAILABLE';
        break;
      }
      case 'dimensionDiscrepanciesResolved': {
        path = './dimension-discrepancies?state=RESOLVED&sortColumn=updatedAt&sort=desc';
        break;
      }
      case 'expiresSoonEstimated': {
        path = './cases?state=AVAILABLE&daysTillCaseExpiration=<=30&sortColumn=daysTillCaseExpiration&sort=asc';
        break;
      }
      case 'underReimbursedEstimated': {
        path = './cases?state=UNDER_REIMBURSED&sortColumn=reimbursedDiscrepancy&sort=desc';
        break;
      }
      case 'investigateEstimated': {
        path = './cases?state=INVESTIGATE';
        break;
      }
      default: {
        path = './cases?state=AVAILABLE';
      }
    }
    window.location.href = path;
  }

  requestData() {
    this.setState({
      isLoading: true,
    });
    const { enqueueSnackbar } = this.props;

    const url = `${process.env.REACT_APP_ORCHESTRATOR}/dashboard`;

    Request.get(url, {
      params: {
        // TODO: retrieve marketplaceId from session.
        // marketplaceId: 1,
      },
    })
      .then((result) => {
        const { isMounted } = this.state;

        if (result.data.data[0] && isMounted) {
          this.setState({
            summary: result.data.data[0] || {},
          });
        } else {
          const message = 'Dashboard summary failed to load';
          enqueueSnackbar(message, { variant: StatusEnum.ERROR, persist: true });
          console.error('Dashboard:', result);
        }
      })
      .catch((error) => {
        const message = 'An error occurred while loading the dashboard summary';
        enqueueSnackbar(message, { variant: StatusEnum.ERROR, persist: true });
        console.error(message, error);
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    const { isLoading, summary, selectedMetrics } = this.state;

    return (
      <SummaryContext.Provider value={{
        summary,
        isLoading,
        requestData: this.requestData,
      }}
      >
        <Section>
          <Carousel onMetricSelect={this.onMetricSelect} selectedMetrics={selectedMetrics} />
        </Section>
        <Insights />
      </SummaryContext.Provider>
    );
  }
}

Summary = withSnackbar(Summary);

export default Summary;
