import { Request } from '@rlx-pros/react-foundation';

Request.interceptors.request.use(
  (originalConfig) => {
    const config = originalConfig;

    if (process.env.REACT_APP_APP_ID) {
      config.headers['x-etlz-app-id'] = process.env.REACT_APP_APP_ID;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

export default Request;
