import CaseTypeEnum from 'shared/enum/CaseStateEnum';
import DateFormatEnum from 'shared/enum/DateFormatEnum';
import httpStatusCodes from 'http-status-codes';
import MarketplaceEnum from 'shared/enum/MarketplaceEnum';
import moment from 'moment';
import queryString from 'query-string';
import React, { Component } from 'react';
import Request from 'shared/interceptors/Request';
import { Drawer, Table, withSnackbar } from '@rlx-pros/react-foundation';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { formatApprovalDate } from 'shared/formatDates';
import { formatCurrency } from 'shared/number';
import { getCurrentMarketplace, getMarketplaceType } from 'shared/marketplace';
import CaseDetails from './casesTable/CaseDetails';
import CreateCaseDrawer from './casesTable/CreateCaseDrawer';
import IgnoreCaseModal from '../modals/IgnoreCaseModal';
import RecreateCaseModal from '../modals/RecreateCaseModal';
import { generateReportUrl } from './casesTable/createCaseInstructions';

class CasesTable extends Component {
  constructor(props) {
    super(props);
    let columns = [
      {
        title: 'Type',
        field: 'type',
        editable: 'never',
        customFilterAndSearch: (search, data) => Table.filter.text('type', search, data),
      },
      {
        title: 'Identifier',
        field: 'identifier',
        editable: 'never',
        customFilterAndSearch: (search, data) => Table.filter.text('identifier', search, data),
      },
      {
        title: 'State',
        field: 'state',
        editable: 'never',
        lookup: {
          AVAILABLE: [CaseTypeEnum.AVAILABLE],
          IGNORED: [CaseTypeEnum.IGNORED],
          CREATED: [CaseTypeEnum.CREATED],
          INVESTIGATE: [CaseTypeEnum.INVESTIGATE],
          REIMBURSED: [CaseTypeEnum.REIMBURSED],
          UNDER_REIMBURSED: [CaseTypeEnum.UNDER_REIMBURSED],
          RESOLVED: [CaseTypeEnum.RESOLVED],
          RECREATE: [CaseTypeEnum.RECREATE],
          CLOSED: [CaseTypeEnum.CLOSED],
        },
        defaultFilter: [CaseTypeEnum.AVAILABLE],
        render: (rowData) => CaseTypeEnum[rowData.state],
      },
      {
        title: 'Est. Value ($)',
        field: 'estimatedValue',
        editable: 'never',
        customFilterAndSearch: (search, data) => Table.filter.numeric('estimatedValue', search, data),
        render: (cellValue) => (cellValue.estimatedValue ? formatCurrency(cellValue.estimatedValue) : ''),
      },
      {
        title: 'Amazon Case Id',
        field: 'amazonCaseId',
        editable: 'never',
        customFilterAndSearch: (search, data) => Table.filter.numeric('amazonCaseId', search, data),
        render: (cellValue) => {
          const { amazonCaseId } = cellValue;
          const marketplaceId = getCurrentMarketplace();
          const marketplaceType = getMarketplaceType(marketplaceId);
          const { caseUrl } = marketplaceType;

          return <a target="_blank" rel="noopener noreferrer" href={`${caseUrl}${amazonCaseId}`}>{amazonCaseId}</a>;
        },
      },
      {
        title: 'Days Until Expires',
        field: 'daysTillCaseExpiration',
        editable: 'never',
        customFilterAndSearch: (search, data) => Table.filter.numeric('daysTillCaseExpiration', search, data),
      },
      {
        title: 'Reimbursed ($)',
        field: 'reimbursed',
        editable: 'never',
        customFilterAndSearch: (search, data) => Table.filter.numeric('reimbursed', search, data),
        render: (cellValue) => {
          if (cellValue.reimbursementId) {
            const marketplaceId = getCurrentMarketplace();

            const url = generateReportUrl('REIMBURSEMENTS', {
              marketplace: getMarketplaceType(marketplaceId),
              eventDateOption: 365,
              reimbursementId: cellValue.reimbursementId,
              reportTime: cellValue.reportTime,
            });

            return (
              <a target="_blank" rel="noopener noreferrer" href={url}>
                {formatCurrency(cellValue.reimbursed)}
              </a>
            );
          }

          return '';
        },
      },
      {
        title: 'Reimbursement Approval Date',
        field: 'reimbursementApprovalDate',
        editable: 'never',
        customFilterAndSearch: (search, data) => Table.filter.date('reimbursementApprovalDate', search, data),
        render: (data) => {
          if (data.reimbursementApprovalDate) {
            return (
              moment(data.reimbursementApprovalDate).format('MM.DD.YYYY')
            );
          }

          return '';
        },
      },
    ];

    const filters = queryString.parse(props.location.search);
    // If no sorting params are provided, sort by default
    filters.sortColumn = filters.sortColumn || 'estimatedValue';
    filters.sort = filters.sort || 'desc';

    columns = columns.map((column) => {
      if (filters[column.field] !== undefined) {
        if (filters[column.field]) {
          if (column.lookup) {
            const filter = String(filters[column.field]).split(',');
            column.defaultFilter = filter;
          } else {
            column.defaultFilter = filters[column.field];
          }
        } else {
          // if key exists in filter with no value remove default filter.
          column.defaultFilter = undefined;
        }
      }

      if (column.field === filters.sortColumn) {
        column.defaultSort = filters.sort;
      }

      return column;
    });

    this.state = {
      columns,
      isLoading: true,
      isCreateDrawerOpen: false,
      isDetailsDrawerOpen: false,
      selectedCase: {},
      isIgnoreCaseModalOpen: false,
      isIgnoreCaseModalProcessing: false,
      isRecreateCaseModalOpen: false,
      isRecreateCaseModalProcessing: false,
      caseId: null,
      caseType: null,
      cases: [],
    };
  }

  componentDidMount() {
    this.requestData();
  }

  requestData = async () => {
    this.setState({ isLoading: true });
    const url = `${process.env.REACT_APP_ORCHESTRATOR}/cases`;
    await Request.get(url, MarketplaceEnum)
      .then((result) => {
        const cases = result.data.data.map((row) => {
          if (row.reimbursementApprovalDate) {
            row.reimbursementApprovalDate = formatApprovalDate(row.reimbursementApprovalDate, DateFormatEnum.MYSQL_DATE, DateFormatEnum.MYSQL_DATE);
          }

          return row;
        });

        this.setState({ cases });
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  toggleDetailsDrawer = () => {
    const { isDetailsDrawerOpen } = this.state;

    this.setState({
      isDetailsDrawerOpen: !isDetailsDrawerOpen,
    });
  }

  ignoreCase = async () => {
    this.setState({
      isIgnoreCaseModalProcessing: true,
    });
    const { caseId } = this.state;
    const { enqueueSnackbar, user } = this.props;

    const url = `${process.env.REACT_APP_ORCHESTRATOR}/ignore-case`;
    await Request.put(url, {
      caseId,
      user: user.attributes.email,
    })
      .then((result) => {
        if (result.status === httpStatusCodes.OK) {
          const { data } = result;
          if (data.result === 1) {
            enqueueSnackbar('Case successfully ignored', {
              variant: 'success',
              persist: false,
            });
          } else {
            throw new Error(data);
          }
        }
      })
      .catch((error) => {
        enqueueSnackbar('Something went wrong while ignoring the selected case.', {
          variant: 'error',
          persist: true,
        });
        console.error(error);
      })
      .finally(() => {
        this.requestData();
        this.setState({
          isIgnoreCaseModalProcessing: false,
          isIgnoreCaseModalOpen: false,
        });
      });
  }

  closeIgnoreCaseModal = () => {
    this.setState({
      isIgnoreCaseModalOpen: false,
    });
  }

  handleCreateCase = async (caseToCreate) => {
    const { state, type, id } = caseToCreate;

    if (state === 'CREATED' || state === 'INVESTIGATE' || state === 'UNDER_REIMBURSED') {
      this.setState({
        caseId: id,
        caseType: type,
        isRecreateCaseModalOpen: true,
      });
    } else {
      this.toggleCreateDrawer(true);

      this.setState({ selectedCase: caseToCreate });
    }
  }

  recreateCase = async () => {
    this.setState({
      isRecreateCaseModalProcessing: true,
    });
    const { caseId, caseType } = this.state;
    const { enqueueSnackbar, user } = this.props;

    const url = `${process.env.REACT_APP_ORCHESTRATOR}/case/recreate`;
    await Request.put(url, {
      id: caseId,
      type: caseType,
      user: user.attributes.email,
    })
      .then((result) => {
        if (result.status === httpStatusCodes.OK) {
          const { data } = result;
          if (data.statusCode !== httpStatusCodes.OK) {
            enqueueSnackbar(data.data.message, {
              variant: 'error',
              persist: true,
            });
          }
        }
      })
      .catch((error) => {
        enqueueSnackbar('Something went wrong while recreating the selected case.', {
          variant: 'error',
          persist: true,
        });
        console.error(error);
      })
      .finally(() => {
        this.setState({
          isRecreateCaseModalProcessing: false,
          isRecreateCaseModalOpen: false,
        });
        this.requestData();
      });
  };

  closeRecreateCaseModal = () => {
    this.setState({
      isRecreateCaseModalOpen: false,
    });
  }

  toggleCreateDrawer = (isOpen) => {
    this.setState({ isCreateDrawerOpen: isOpen });
  };

  render() {
    const {
      cases,
      columns,
      selectedCase,
      isDetailsDrawerOpen,
      isLoading,
      isIgnoreCaseModalOpen,
      isIgnoreCaseModalProcessing,
      isRecreateCaseModalOpen,
      isRecreateCaseModalProcessing,
      isCreateDrawerOpen,
    } = this.state;

    const actions = [
      {
        icon: 'launch',
        tooltip: 'View Details',
        position: 'row',
        onClick: (event, rowData) => {
          event.preventDefault();
          this.setState({ selectedCase: rowData });
          this.toggleDetailsDrawer();
        },
      },
      {
        icon: 'add_circle_outline',
        tooltip: 'Create Case',
        onClick: (event, caseToCreate) => {
          event.preventDefault();
          this.handleCreateCase(caseToCreate);
        },
      },
      {
        icon: 'block',
        tooltip: 'Ignore Case',
        onClick: (event, caseToIgnore) => {
          event.preventDefault();
          this.setState({
            caseId: caseToIgnore.id,
            isIgnoreCaseModalOpen: true,
          });
        },
      },
    ];

    return (
      <div>
        <Table
          loading={isLoading}
          columns={columns}
          actions={actions}
          options={{
            filtering: true,
            grouping: false,
            search: false,
            draggable: false,
            sorting: true,
          }}
          data={cases}
          title="Cases"
        />
        <CreateCaseDrawer
          anchor="right"
          open={isCreateDrawerOpen}
          close={() => this.toggleCreateDrawer(false)}
          caseInfo={selectedCase}
          refresh={this.requestData}
        />
        <Drawer
          anchor="right"
          open={isDetailsDrawerOpen}
          onClose={this.toggleDetailsDrawer}
        >
          <Drawer.Bar close={this.toggleDetailsDrawer} />
          <CaseDetails
            toggleDrawer={this.toggleDetailsDrawer}
            selectedCase={selectedCase}
          />
        </Drawer>
        <IgnoreCaseModal isOpen={isIgnoreCaseModalOpen} isProcessing={isIgnoreCaseModalProcessing} closeCallback={this.closeIgnoreCaseModal} submitCallback={this.ignoreCase} />
        <RecreateCaseModal
          isOpen={isRecreateCaseModalOpen}
          isProcessing={isRecreateCaseModalProcessing}
          closeCallback={this.closeRecreateCaseModal}
          submitCallback={this.recreateCase}
        />
      </div>
    );
  }
}

CasesTable = withRouter(CasesTable);
const mapStateToProps = (state) => ({
  user: state.global.user,
});

export default connect(
  mapStateToProps,
)(withSnackbar(CasesTable));
