export function checkForValidNumber(number) {
  if (Number.isNaN(parseFloat(number)) || number === Infinity) {
    number = 0;
  }

  return number;
}

export function formatNumber(number, precision = 0) {
  let validateNumber = number;
  validateNumber = checkForValidNumber(number);

  if (typeof validateNumber === 'string') {
    validateNumber = parseFloat(validateNumber);
  }
  const num = Math.round((validateNumber + Number.EPSILON) * 100) / 100;

  return parseFloat(num).toFixed(precision).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatCurrency(number) {
  return `$${formatNumber(number, 2)}`;
}

export function formatPercentage(number) {
  return `${formatNumber(number, 2)}%`;
}

export function formatCarouselMetric(number) {
  return number > 1000 ? Math.round(number) : number;
}

export default {
  formatCurrency,
  formatNumber,
  formatPercentage,
  formatCarouselMetric,
};
