import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import {
  ApplicationContent,
  Core,
  NotFound,
  Page,
  RequiresAuthentication,
  setClient,
  setUser,
  SignOut,
} from '@rlx-pros/react-foundation';
import Headerbar from './app/Headerbar';
import '@rlx-pros/react-foundation/dist/react-foundation.min.css';
// Routes
import Cases from './Cases';
import Dashboard from './Dashboard';
import DimensionDiscrepancies from './DimensionDiscrepancies';

class App extends Component {
  render() {
    const { setClient, setUser } = this.props;

    return (
      <BrowserRouter>
        <Page trackingCode={process.env.REACT_APP_GA_TRACKING_CODE}>
          <RequiresAuthentication
            appEnvironment={process.env.REACT_APP_ENVIRONMENT}
            applicationId={process.env.REACT_APP_APP_ID}
            authenticationDomain={process.env.REACT_APP_AUTHENTICATION_DOMAIN}
            callbackSetClient={setClient}
            callbackSetUser={setUser}
            cookieDomain={process.env.REACT_APP_COOKIE_DOMAIN}
            cookieSecurity={process.env.REACT_APP_COOKIE_SECURITY}
            platformOrchestrator={process.env.REACT_APP_PLATFORM_ORCHESTRATOR}
            region={process.env.REACT_APP_REGION}
            userpoolId={process.env.REACT_APP_USERPOOL_ID}
            webClientId={process.env.REACT_APP_WEB_CLIENT_ID}
          >
            <Core>
              <Headerbar />
              <ApplicationContent>
                <Switch>
                  <Route exact path="/">
                    <Redirect to="/dashboard" />
                  </Route>
                  <Route path="/dashboard" component={Dashboard} />
                  <Route path="/cases" component={Cases} />
                  <Route path="/dimension-discrepancies" component={DimensionDiscrepancies} />
                  <Route path="/sign-out" component={SignOut} />
                  <Route component={NotFound} />
                </Switch>
              </ApplicationContent>
            </Core>
          </RequiresAuthentication>
        </Page>
      </BrowserRouter>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setClient: (client) => dispatch(setClient(client)),
  setUser: (user) => dispatch(setUser(user)),
});

App = connect(
  null,
  mapDispatchToProps,
)(App);

export default App;
