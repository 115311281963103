import React, { Component } from 'react';
import {
  ContentHeader,
} from '@rlx-pros/react-foundation';
import Summary from './dashboard/Summary';

class Dashboard extends Component {
  render() {
    return (
      <div>
        <ContentHeader title="Dashboard" subtitle="Channel Insights" size="medium" color="white" />
        <Summary />
      </div>
    );
  }
}

export default Dashboard;
