import React from 'react';
import SummaryContext from './SummaryContext';
/* eslint-disable react/jsx-props-no-spreading */
const withSummary = (Component) => {
  const WrappedComponent = React.forwardRef((props, ref) => (
    <SummaryContext.Consumer>
      {(context) => {
        if (context) {
          return (
            <Component
              {...props}
              ref={ref}
              summary={context.summary}
              isSummaryLoading={context.isLoading}
              reloadSummary={context.requestData}
            />
          );
        }

        return (<Component {...props} ref={ref} />);
      }}
    </SummaryContext.Consumer>
  ));

  return WrappedComponent;
};

export default withSummary;
