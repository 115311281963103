import React, { Component } from 'react';
import {
  // Components
  Columns,
  Section,
  // Functions
  s3AssetsJson,
} from '@rlx-pros/react-foundation';
import Insight from './Insight';

export default class Insights extends Component {
  constructor(props) {
    super(props);

    this.state = {
      insights: {},
      settings: {},
    };
  }

  componentDidMount() {
    const appEnvironment = process.env.REACT_APP_ENVIRONMENT;
    s3AssetsJson(appEnvironment, 'content/channel-auditor/insights').then((data) => this.setState({ insights: data }));
  }

  render() {
    const { insights, settings } = this.state;

    return (
      <Section>
        <Columns className="is-multiline">
          {(insights.insights || []).map((insight, index) => {
            const path = insight.path.replace(/:(.*):/g, (match, first) => settings[first]);
            const keyName = `insight_${index}`;

            return (
              <Insight
                key={keyName}
                title={insight.title}
                subtitle={insight.subtitle}
                description={insight.description}
                path={path}
                link={insights.link}
              />
            );
          })}
        </Columns>
      </Section>
    );
  }
}
