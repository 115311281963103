import React, { Component } from 'react';
import { Button, Modal } from '@rlx-pros/react-foundation';

class IgnoreCaseModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isProcessing: false,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      isOpen: nextProps.isOpen,
      isProcessing: nextProps.isProcessing,
    };
  }

  close = () => {
    const { closeCallback } = this.props;
    closeCallback();
  }

  ignore = () => {
    const { submitCallback } = this.props;
    submitCallback();
  }

  render() {
    const { isOpen, isProcessing } = this.state;
    const listStyle = {
      marginLeft: '1em',
    };

    return (
      <Modal
        open={isOpen}
        onClose={this.close}
        contentLabel="Ignore Case"
      >
        <p>
          Are you sure you want to ignore this case?
          <br />
          <br />
          You should only ignore a case if:
        </p>
        <ul>
          <li style={listStyle}>1. The case was previously created manually</li>
          <li style={listStyle}>2. The estimated value is too low</li>
          <li style={listStyle}>3. You know the case will not get reimbursed for some reason</li>
        </ul>
        <br />
        <Button.Group position="right">
          <Button outlined disabled={isProcessing} onClick={this.close}>Cancel</Button>
          <Button color="danger" loading={isProcessing} onClick={this.ignore}>Ignore</Button>
        </Button.Group>
      </Modal>
    );
  }
}

export default IgnoreCaseModal;
