import DateFormatEnum from 'shared/enum/DateFormatEnum';
import React, { Component } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  Columns,
  ContentHeader,
  List,
  ListItem,
  ListItemText,
  Section,
  withSnackbar,
} from '@rlx-pros/react-foundation';
import Request from 'shared/interceptors/Request';
import CaseDetailsEnum from 'shared/enum/CaseDetailsEnum';
import ReimbursementDetailsEnum from 'shared/enum/ReimbursementDetailsEnum';
import { getCurrentMarketplace, getMarketplaceType } from 'shared/marketplace';
import { formatApprovalDate } from 'shared/formatDates';
import { generateReportUrl } from './createCaseInstructions';

class CaseDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      caseDetails: null,
      isLoading: true,
      reimbursementDetails: null,
    };
  }

  componentDidMount() {
    const { selectedCase } = this.props;

    if (selectedCase) {
      this.requestData();
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedCase } = this.props;

    if (prevProps.selectedCase !== selectedCase) {
      this.requestData();
    }
  }

  requestData = () => {
    this.setState({
      isLoading: true,
    });
    const { enqueueSnackbar, selectedCase } = this.props;
    const url = `${process.env.REACT_APP_ORCHESTRATOR}/case-details`;
    const { id, typeEnum } = selectedCase;

    Request.get(url, {
      params: {
        caseId: id,
        caseType: typeEnum,
      },
    }).then((result) => {
      if (result.data) {
        const caseDetails = result.data.data.caseDetails[0];
        const reimbursementDetails = result.data.data.reimbursementDetails[0];

        // data from the CasesTable that also needs to be displayed with case details
        caseDetails.type = selectedCase.type;
        caseDetails.state = selectedCase.state;
        caseDetails.estimatedValue = selectedCase.estimatedValue;
        caseDetails.daysUntilCaseExpires = selectedCase.daysUntilCaseExpires;
        caseDetails.reportTime = selectedCase.reportTime;

        if (reimbursementDetails) {
          let { approvalDate } = reimbursementDetails;
          approvalDate = formatApprovalDate(approvalDate, DateFormatEnum.MYSQL_DATE, DateFormatEnum.MYSQL_DATE);
          reimbursementDetails.approvalDate = approvalDate;
        }

        this.setState({ caseDetails, reimbursementDetails });
      }
    })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar('Case Details failed to load', {
          persist: true,
          variant: 'Error',
        });
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  splitDetails = (details, enumerator) => {
    if (details) {
      const components = this.formatDetailsForDisplay(details, enumerator)
        .filter((component) => component !== null);
      const split = Math.ceil(components.length / 2);

      return (
        <List>
          <Columns>
            <Columns.Column>
              {components.slice(0, split)}
            </Columns.Column>
            <Columns.Column>
              {components.slice(split, components.length)}
            </Columns.Column>
          </Columns>
        </List>
      );
    }

    return <></>;
  }

  formatDetailsForDisplay = (object, enumerator) => {
    const enumeratorKeys = Object.keys(enumerator);

    const filteredDetails = Object.keys(object).filter((key) => enumeratorKeys.includes(key));

    return filteredDetails.map((key, index) => {
      if (object[key]) {
        let url;
        const marketplaceId = getCurrentMarketplace();
        const marketplaceType = getMarketplaceType(marketplaceId);
        const { caseUrl } = marketplaceType;

        switch (key) {
          case 'amazonCaseId':
            url = `${caseUrl}${object[key]}`;
            break;
          case 'reimbursementId':
            url = generateReportUrl('REIMBURSEMENTS', {
              reportTime: new Date(),
              reimbursementId: object[key],
              marketplace: marketplaceType,
              eventDateOption: 365,
            });
            break;
          default:
            break;
        }

        const value = url ? (
          <a target="_blank" rel="noopener noreferrer" href={url}>{object[key]}</a>
        ) : (
          <span>{object[key]}</span>
        );

        return (
          // eslint-disable-next-line react/no-array-index-key
          <ListItem key={`case-detail-${index}`}>
            <ListItemText
              primary={enumerator[key]}
              secondary={value}
            />
          </ListItem>
        );
      }
      return null;
    });
  }

  render() {
    const {
      caseDetails,
      isLoading,
      reimbursementDetails,
    } = this.state;

    const skeleton = (
      <div>
        <div style={{ display: 'inline-block', marginRight: '3%', width: '45%' }}>
          <Skeleton variant="text" width={120} height={50} />
          <Skeleton variant="text" width={120} height={50} />
          <Skeleton variant="text" width={120} height={50} />
          <Skeleton variant="text" width={120} height={50} />
          <Skeleton variant="text" width={120} height={50} />
          <Skeleton variant="text" width={120} height={50} />
        </div>
        <div style={{ display: 'inline-block', width: '20%' }}>
          <Skeleton variant="text" height={50} style={{ width: '60%' }} />
          <Skeleton variant="text" height={50} style={{ width: '60%' }} />
          <Skeleton variant="text" height={50} style={{ width: '60%' }} />
          <Skeleton variant="text" height={50} style={{ width: '60%' }} />
          <Skeleton variant="text" height={50} style={{ width: '60%' }} />
          <Skeleton variant="text" height={50} style={{ width: '60%' }} />
        </div>
      </div>
    );

    return (
      <Section>
        <ContentHeader title="Case Details" color="white" />
        {isLoading ? (skeleton) : this.splitDetails(caseDetails, CaseDetailsEnum)}
        { reimbursementDetails ? (
          <ContentHeader title="Reimbursement Details" color="white" />
        ) : ' '}
        {isLoading ? (skeleton)
          : (reimbursementDetails ? this.splitDetails(reimbursementDetails, ReimbursementDetailsEnum) : ' ')}
      </Section>
    );
  }
}

export default withSnackbar(CaseDetails);
