const CaseStateEnum = {
  AVAILABLE: 'AVAILABLE',
  IGNORED: 'IGNORED',
  CREATED: 'CREATED',
  INVESTIGATE: 'INVESTIGATE',
  REIMBURSED: 'REIMBURSED',
  UNDER_REIMBURSED: 'UNDER REIMBURSED',
  RESOLVED: 'RESOLVED',
  RECREATE: 'RECREATE',
  CLOSED: 'CLOSED',
  WAITING: 'WAITING',
};

export default CaseStateEnum;
