import moment from 'moment-timezone';
import TimezoneEnum from './enum/TimezoneEnum';

export function formatApprovalDate(time, inFormat = 'YYYY-MM-DD HH:mm:ss', outFormat = 'MM.DD.YYYY') {
  const date = moment.utc(time, inFormat).tz(TimezoneEnum.PST);

  return date.format(outFormat);
}

export default { formatApprovalDate };
