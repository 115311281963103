import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  Columns,
  Content,
  Heading,
  Media,
} from '@rlx-pros/react-foundation';

const Insight = ({
  title, subtitle, description, path, link,
}) => (
  <Columns.Column size={12}>
    <Card>
      <Card.Content>
        <Media>
          <Media.Item>
            <Heading size={4}>
              {title}
            </Heading>
            <Heading subtitle size={6}>
              {subtitle}
            </Heading>
          </Media.Item>
        </Media>
        <Content
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Card.Content>
      <Card.Footer>
        <Card.Footer.Item
          renderAs={Link}
          to={path}
        >
          {link}
        </Card.Footer.Item>
      </Card.Footer>
    </Card>
  </Columns.Column>
);

export default Insight;
