import React, { Component } from 'react';
import {
  ContentHeader,
  Section,
} from '@rlx-pros/react-foundation';
import CasesTable from './cases/CasesTable';

class Cases extends Component {
  render() {
    return (
      <div>
        <ContentHeader title="Cases" size="medium" color="white" />
        <Section>
          <CasesTable />
        </Section>
      </div>
    );
  }
}

export default Cases;
