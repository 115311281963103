const CaseDetailsEnum = {
  amazonCaseId: 'Amazon Case Id',
  amazonOrderId: 'Amazon Order Id',
  amazonOrderIds: 'Amazon Order Ids',
  asin: 'ASIN',
  chargedCommission: 'Charged Commission',
  chargedFulfillmentFee: 'Charged Fulfillment Fee',
  commission: 'Commission',
  damaged: 'Damaged',
  daysTillCaseExpiration: 'Days Until Case Expires',
  destinationFulfillmentCenterId: 'Destination Fulfillment Center Id',
  difference: 'Difference',
  estimatedValue: 'Estimated Value',
  fnsku: 'Fnsku',
  fnskus: 'Fnskus',
  found: 'Found',
  fulfillmentCenterId: 'Fulfillment Center Id',
  fulfillmentFee: 'Fulfillment Fee',
  goodwill: 'Goodwill',
  identifier: 'Identifier',
  itemPackageWeight: 'Item Package Weight',
  longestSide: 'Longest Side',
  lost: 'Lost',
  maxSnapshotDate: 'Max Snapshot Date',
  medianSide: 'Median Side',
  ordered: 'Ordered',
  orderId: 'Order Id',
  overage: 'Overage',
  previousFulfillmentFee: 'Previous Fulfillment Fee',
  previousLongestSide: 'Previous Longest Side',
  previousMedianSide: 'Previous Median Side',
  previousPackageWeight: 'Previous Package Weight',
  previousShortestSide: 'Previous Shortest Side',
  reason: 'Reason',
  refunded: 'Refunded',
  reimbursed: 'Reimbursed',
  reportTime: 'Report Time',
  returned: 'Returned',
  returnedInventory: 'Returned Inventory',
  sellerOrderId: 'Seller Order Id',
  shipmentId: 'Shipment Id',
  shortestSide: 'Shortest Side',
  sku: 'Sku',
  state: 'State',
  type: 'Type',
  unitOfDimension: 'Unit Of Dimension',
  unitOfWeight: 'Unit Of Weight',
  yourPrice: 'Your Price',
};

export default CaseDetailsEnum;
