import React, { Component } from 'react';
import {
  ContentHeader,
  Section,
} from '@rlx-pros/react-foundation';
import DimensionDiscrepanciesTable from './discrepancy/DimensionDiscrepanciesTable';

class DimensionDiscrepancies extends Component {
  render() {
    return (
      <div>
        <ContentHeader title="Dimension Discrepancies" size="medium" color="white" />
        <Section>
          <DimensionDiscrepanciesTable />
        </Section>
      </div>
    );
  }
}

export default DimensionDiscrepancies;
